<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block-items">
          <h2>Further Help</h2>

          <b-button
            class="action button-item"
            size="sm"
            variant="info"
            @click="externalLink('faq')"
            >FAQs</b-button
          >

          <b-button
            class="action button-item"
            size="sm"
            variant="info"
            @click="externalLink('contact')"
            >Contact Us</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { externalLinks } from "@/components/mixins/external-links";

export default {
  name: "PrePayHelp",
  mixins: [externalLinks],
  data: function() {
    return {
      eventNameAppend: "PrePay"
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

.block-items {
  text-align: left;
}

.button-item {
  margin-top: 10px;
  margin-left: 20px;
}
</style>
